import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 人员管理 - 分页查询
 * @param params
 */
export function getcustomerList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/staff/getPageList',
    method: 'get',
    params,
  });
}
/**
* 人员管理 - 新增
* @param params
*/
export function addPersonnel(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/staff/insert',
    method: 'post',
    data: params,
  });
}
/**
* 人员管理 - 直属上级
* @param params
*/
export function getsuperiorData(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/staff/getList',
    method: 'get',
    params,
  });
}
/**
* 人员管理 - 姓名查询
* @param params
*/
export function getUserList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/user/userList',
    method: 'get',
    params,
  });
}
/**
* 人员管理 - 删除
* @param params
*/
export function deleteUserId(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/staff/delete',
    method: 'get',
    params,
  });
}
/**
* 人员管理 - 详情
* @param params
*/
export function getUserDetail(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/staff/getDetail',
    method: 'get',
    params,
  });
}
/**
* 人员管理 - 编辑
* @param params
*/
export function updatePersonnel(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/staff/update',
    method: 'post',
    data: params,
  });
}
/**
* 客户列表 - 分页
* @param params
*/
export function getList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/getPageList',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 人员列表
* @param params
*/
export function getSalePersonList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/getUnderList',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 项目列表
* @param params
*/
export function getStoreList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/web/roomData/getStoreList',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 楼栋列表
* @param params
*/
export function getBuildList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/web/roomData/getBuildingList',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 房间号列表
* @param params
*/
export function getRoomList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/web/roomData/getRentableRoomList',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 新增客户
* @param params
*/
export function addCustomers(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/insert',
    method: 'post',
    data: params,
  });
}
/**
* 客户列表 - 删除客户
* @param params
*/
export function deleteCustomers(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/delete',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 客户详情
* @param params
*/
export function getCustomerDatail(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/detail',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 写跟进
* @param params
*/
export function writeFollow(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/followUp',
    method: 'post',
    data: params,
  });
}
/**
* 客户列表 - 放入公海
* @param params
*/
export function transferCustomers(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/editStatus',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 编辑客户
* @param params
*/
export function updateCustomers(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/update',
    method: 'post',
    data: params,
  });
}
/**
* 客户列表 - 转移客户
* @param params
*/
export function moveCustomers(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/transfer',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 更改房源状态
* @param params
*/
export function changeRoomStatus(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/editRoomStatus',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 导出
* @param params
*/
export function customerListExport(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/export',
    method: 'get',
    params,
  });
}
/**
* 公海池 - 人员列表
* @param params
*/
export function publicCustomerList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/queryPubCList',
    method: 'get',
    params,
  });
}
/**
* 公海池 - 导出
* @param params
*/
export function publicCustomerListExport(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/pubExport',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 取消状态
* @param params
*/
export function cancelCustomerStatus(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/cancelStatus',
    method: 'get',
    params,
  });
}
/**
* 客户线索 - 列表
* @param params
*/
export function getLeadsList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/getPageList',
    method: 'get',
    params,
  });
}
/**
* 客户线索 - 提交
* @param params
*/
export function addLeadsList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/insert',
    method: 'post',
    data: params,
  });
}
/**
* 客户线索 - 详情
* @param params
*/
export function getLeadsDetail(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/getDetail',
    method: 'get',
    params,
  });
}
/**
* 客户线索 - 删除
* @param params
*/
export function deleteCustomerLeads(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/delete',
    method: 'get',
    params,
  });
}
/**
* 客户线索 - 导出
* @param params
*/
export function exportCustomerList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/export',
    method: 'get',
    params,
  });
}
/**
* 客户线索 - 转化为客户
* @param params
*/
export function inversionCustomer(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/convertToCustomer',
    method: 'get',
    params,
  });
}
/**
* 客户线索 - 编辑
* @param params
*/
export function editLeadsList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/edit',
    method: 'post',
    data: params,
  });
}
/**
* 客户线索 - 查重数据
* @param params
*/
export function getRepeatList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/repeat',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 延期&驳回记录
* @param params
*/
export function getStatusList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/getRecords',
    method: 'get',
    params,
  });
}
/**
* 客户列表 - 设为预定
* @param params
*/
export function operateApply(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/reservation',
    method: 'post',
    data: params,
  });
}
/**
* 审批列表
* @param params
*/
export function getprossList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/getPageList',
    method: 'get',
    params,
  });
}
/**
* 审批列表 - 详情
* @param params
*/
export function getprossListDetail(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/getDetail',
    method: 'get',
    params,
  });
}

/**
* 审批列表 - 通过
* @param params
*/
export function operatePass(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/pass',
    method: 'post',
    data: params,
  });
}
/**
* 审批列表 - 驳回
* @param params
*/
export function operateReject(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/reject',
    method: 'post',
    data: params,
  });
}
/**
* 审批列表 - 导出
* @param params
*/
export function approvalExport(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/approval/export',
    method: 'get',
    params,
  });
}
/**
* 渠道管理 - 列表
* @param params
*/
export function getAvenueList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/avenues/list',
    method: 'get',
    params,
  });
}
/**
* 渠道管理 - 添加
* @param params
*/
export function addAvenueData(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/avenues/add',
    method: 'post',
    data: params,
  });
}
/**
* 渠道管理 - 更新
* @param params
*/
export function updataAvenueData(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/avenues/edit',
    method: 'post',
    data: params,
  });
}
/**
* 渠道管理 - 删除
* @param params
*/
export function deleteAvenueData(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/avenues/delete',
    method: 'get',
    params,
  });
}
/**
* 公海池 - 批量分配
* @param params
*/
export function shareCustomerList(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/customer/batchAssign',
    method: 'post',
    data: params,
  });
}

/**
* 线索管理 - 是否接通电话
* @param params
*/
export function switchConnect(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/leads/isConnect',
    method: 'get',
    params,
  });
}